import { Row, Col,Layout, Space, Carousel } from 'antd';
import './Cooperate.css'
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import cooperate1 from '../../../../image/cooperate/cooperate1.png'
import cooperate2 from '../../../../image/cooperate/cooperate2.png'
import cooperate3 from '../../../../image/cooperate/cooperate3.png'
import cooperate4 from '../../../../image/cooperate/cooperate4.png'
import logo1 from '../../../../image/cooperate/logo/博商.png'
import logo2 from '../../../../image/cooperate/logo/卡姿兰.png'
import logo3 from '../../../../image/cooperate/logo/徕芬.PNG'
import logo4 from '../../../../image/cooperate/logo/必要.png'
import logo5 from '../../../../image/cooperate/logo/溪木源.png'
import logo6 from '../../../../image/cooperate/logo/航班管家.png'
import logo7 from '../../../../image/cooperate/logo/茶救星球.png'
import logo8 from '../../../../image/cooperate/logo/衬衫老罗.png'
const { Header, Content, Footer } = Layout;

class Cooperate extends Component {
	constructor(props) {
    super(props)
    this.state = {
      headerStyle: {
        textAlign: 'center',
        color: '#1F2329',
        height: 180,
        paddingInline: 162,
        backgroundColor: 'transparent',
        lineHeight: '20px'
      },
      contentStyle: {
        textAlign: 'center',
        lineHeight: 0,
        paddingInline: 100,
        backgroundColor: 'transparent',
      },
      footerStyle: {
        textAlign: 'center',
        paddingInline: 120,
        height: 120,
        backgroundColor: 'transparent',
      },
      cooperateList: [
        {
          key: 0,
          img: cooperate1,
          href: ''
        },
        {
          key: 1,
          img: cooperate2,
          href: ''
        },
        {
          key: 2,
          img: cooperate3,
          href: ''
        },
        {
          key: 3,
          img: cooperate4,
          href: ''
        }
      ]
    }
		this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
	}
	next() {
		this.slider.next();
	}
	prev() {
		this.slider.prev();
  }
	render() {
    const carouselSetting = {
      dots: false,
			lazyLoad: true,
			autoplay:true,
		};
    return (
      <Space
      direction="vertical"
      style={{
        width: 'inherit',
      }}
      size={[0, 48]}
    >
      <Layout className='cooperate-warp'>
          <Header style={this.state.headerStyle}>
            <div className='warp-header'>
              <div className='header-title'>众多企业一致认可</div>
              <Link to="/cooperate" className='header-details'>查看全部方案 &gt;</Link>
            </div>
          </Header>
          <Content className='cooperate-content' style={this.state.contentStyle}>
          <Carousel
            {...carouselSetting}
            ref={el => (this.slider = el)}
            className='content-warp'
          >
            <div className="content-warp-item">
              <Row>
                {this.state.cooperateList.map((item, index) => {
                  return <Col key={index} className='warp-item-img' xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <img src={item.img} alt="" />
                  </Col>
                })}
              </Row>
              </div>
              <div className="content-warp-item">
              <Row>
                {this.state.cooperateList.map((item, index) => {
                  return <Col key={index} className='warp-item-img' xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <img src={item.img} alt="" />
                  </Col>
                })}
              </Row>
            </div>
          </Carousel>
          <div className='arrow-btn'>
            <svg onClick={this.prev} t="1691409597461" className="icon-left" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4211" width="26" height="26"><path d="M290.133333 448H896v85.333333H290.133333l132.266667 132.266667L362.666667 725.333333 128 490.666667 362.666667 256l59.733333 59.733333-132.266667 132.266667z" fill="#1F2329" p-id="4212"></path></svg>
              <svg onClick={this.next} t="1691409107531" className="icon-right" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4008" width="26" height="26"><path d="M729.6 448H128v85.333333h601.6L597.333333 665.6l59.733334 59.733333 234.666666-234.666666L661.333333 256l-59.733333 59.733333 128 132.266667z" fill="#1F2329" p-id="4009"></path></svg>
          </div>
          </Content>
          <Footer className='cooperate-footer' style={this.state.footerStyle}>
            <Row>
              <Col className='warp-item-img-circle' xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <div style={{ backgroundImage: `url(${logo1})` }}></div>
              </Col>
              <Col className='warp-item-img-circle' xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <div style={{ backgroundImage: `url(${logo2})` }}></div>
              </Col>
              <Col className='warp-item-img-circle' xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <div style={{ backgroundImage: `url(${logo3})` }}></div>
              </Col>
              <Col className='warp-item-img-circle' xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <div style={{ backgroundImage: `url(${logo4})` }}></div>
              </Col>
              <Col className='warp-item-img-circle' xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <div style={{ backgroundImage: `url(${logo5})` }}></div>
              </Col>
              <Col className='warp-item-img-circle' xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <div style={{ backgroundImage: `url(${logo6})` }}></div>
              </Col>
              <Col className='warp-item-img-circle' xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <div style={{ backgroundImage: `url(${logo7})` }}></div>
              </Col>
              <Col className='warp-item-img-circle' xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                <div style={{ backgroundImage: `url(${logo8})` }}></div>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </Space>
		)
	}
}

export default Cooperate;