import { Row, Col,Layout, Space, Carousel } from 'antd';
import './Scheme.css'
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import scheme1Img from '../../../../image/scheme/scheme1-img.png'
import scheme1Txt from '../../../../image/scheme/scheme1-txt.png'
import scheme2Img from '../../../../image/scheme/scheme2-img.png'
import scheme2Txt from '../../../../image/scheme/scheme2-txt.png'
import scheme3Img from '../../../../image/scheme/scheme3-img.png'
import scheme3Txt from '../../../../image/scheme/scheme3-txt.png'
const { Header, Content } = Layout;

class Scheme extends Component {
	constructor(props) {
    super(props)
    this.state = {
      headerStyle: {
        textAlign: 'center',
        color: '#1F2329',
        height: 180,
        paddingInline: 333,
        backgroundColor: 'transparent',
        lineHeight: '20px'
      },
      contentStyle: {
        textAlign: 'center',
        lineHeight: 0,
        paddingInline: 130,
        backgroundColor: 'transparent',
      },
      schemeList: [
        {
          key: 0,
          img: scheme1Img,
          imgTxt: scheme1Txt,
          href: '/products'
        },
        {
          key: 1,
          img: scheme2Img,
          imgTxt: scheme2Txt,
          href: ''
        },
        {
          key: 2,
          img: scheme3Img,
          imgTxt: scheme3Txt,
          href: ''
        }
      ]
    }
		this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
	}
	next() {
		this.slider.next();
	}
	prev() {
		this.slider.prev();
  }
	render() {
    const carouselSetting = {
      dots: false,
			lazyLoad: true,
			autoplay:true,
		};
    return (
      <Space
      direction="vertical"
      style={{
        width: 'inherit',
      }}
      size={[0, 48]}
    >
      <Layout className='scheme-warp'>
          <Header style={this.state.headerStyle}>
            <div className='warp-header'>
              <div className='header-title'>定制解决方案</div>
              <Link to="/products" className='header-details'>查看全部方案 &gt;</Link>
            </div>
        </Header>
        <Content className='scheme-content' style={this.state.contentStyle}>
          <Carousel
            {...carouselSetting}
            ref={el => (this.slider = el)}
            className='content-warp'
          >
            {this.state.schemeList.map((item, index) => {
              return <div className="content-warp-item" key={index}>
                <Row>
                  <Col className='warp-item-txt' xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <img src={item.imgTxt} alt="" />
                    <Link to={item.href + '?key=' + item.key} className="item-txt-btn">了解详情</Link>
                  </Col>
                  <Col className='warp-item-img' xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <img src={item.img} alt="" />
                  </Col>
                </Row>
              </div>
            })}
          </Carousel>
          <div className='arrow-btn'>
            <svg onClick={this.prev} t="1691409597461" className="icon-left" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4211" width="26" height="26"><path d="M290.133333 448H896v85.333333H290.133333l132.266667 132.266667L362.666667 725.333333 128 490.666667 362.666667 256l59.733333 59.733333-132.266667 132.266667z" fill="#1F2329" p-id="4212"></path></svg>
              <svg onClick={this.next} t="1691409107531" className="icon-right" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4008" width="26" height="26"><path d="M729.6 448H128v85.333333h601.6L597.333333 665.6l59.733334 59.733333 234.666666-234.666666L661.333333 256l-59.733333 59.733333 128 132.266667z" fill="#1F2329" p-id="4009"></path></svg>
          </div>
        </Content>
      </Layout>
    </Space>
		)
	}
}

export default Scheme;