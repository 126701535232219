import React from 'react';
import { Layout, Space } from 'antd';
import './Adviser.css'
import adviser from '../../../../image/adviser.png'
const { Header, Content } = Layout;

const headerStyle = {
  textAlign: 'center',
  color: '#1F2329',
  height: 180,
  paddingInline: 333,
  backgroundColor: '#FFFFFF',
  lineHeight: '20px'
};
const contentStyle = {
  textAlign: 'center',
  lineHeight: 0,
  backgroundColor: '#FFFFFF',
};

const Adviser = () => {
  
  return(
    <Space
    className='adviser-warp'
      direction="vertical"
      style={{
        width: 'inherit',
      }}
      size={[0, 48]}
    >
      <Layout>
        <Header style={headerStyle}>
          <div className='warp-header'>
            <div className='header-title'>飞书企业效能顾问服务</div>
            <div className='header-details'>为企业输送行业实践，制定效能提升方案</div>
          </div>
          
        </Header>
        <Content style={contentStyle}>
          <div className="content-warp">
            <img src={adviser} alt="" />
            <a target="_blank" className="content-warp-btn" href="https://ldkj.feishu.cn/share/base/form/shrcngfmZgPnboPENOzhhUMqcOc">预约咨询</a>
          </div>
        </Content>
      </Layout>
    </Space>
  )
};
export default Adviser;