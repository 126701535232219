import { Component } from 'react'
import './Index.css'
import Head from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import Banner from './components/banner/Banner'
import Products from './components/products/Products'
import Project from './components/project/Project'
import Scheme from './components/scheme/Scheme'
import Adviser from './components/adviser/Adviser'
import Serve from './components/serve/Serve'
import Cooperate from './components/cooperate/Cooperate'

export class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  // 卸载
  componentWillUnmount() {
  }
  // 创建阶段
  componentDidMount() {
    window.scrollTo(0,0)
  }
  scrollToAnchor = anchorName => {
    if (anchorName == 'aboutUs') {
      let anchorElement = document.getElementById(anchorName)
      if (anchorElement) {
        window.scrollTo(0,anchorElement.offsetTop - window.innerHeight / 2)
      }
    }
  }
  render() {
    return (
      <div className='index-warp'>
        <Head toLink={this.scrollToAnchor} currMenu='index' />
        <Banner />
        <Products />
        <Project />
        <Scheme />
        <Adviser />
        <Serve />
        <Cooperate />
        <Footer />
      </div>
    )
  }
} 