import { Component } from 'react'
import './CooperateDetail.css'
import Head from '../../../../components/header/Header'
import Footer from '../../../../components/footer/Footer'
import cooperateLogo from '../../../../image/cooperate-logo.png'
import page1 from '../../../../image/example/1.png'
import page2 from '../../../../image/example/2.png'
import page3 from '../../../../image/example/3.png'
import page4 from '../../../../image/example/4.png'
import page5 from '../../../../image/example/5.png'
import page6 from '../../../../image/example/6.png'
import page7 from '../../../../image/example/7.png'
import page8 from '../../../../image/example/8.png'
import page9 from '../../../../image/example/9.png'
import { Layout, Space } from 'antd';
const { Content } = Layout;

export class CooperateDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentStyle: {
        textAlign: 'center',
        lineHeight: 0,
        paddingInline: 0,
        backgroundColor: 'transparent',
      },
      currentIndex: 0
    }
  }
  // 卸载
  componentWillUnmount() {
  }
  // 创建阶段
  componentDidMount() {
    window.scrollTo(0,0)
  }
  handleChangeProductCurr = index => {
    this.setState({ currentIndex: index })
  }
  scrollToAnchor = anchorName => {
    if (anchorName == 'aboutUs') {
      let anchorElement = document.getElementById(anchorName)
      if (anchorElement) {
        window.scrollTo(0,anchorElement.offsetTop - window.innerHeight / 2)
      }
    }
  }
  render() {
    return (
      <div className='cooperate-detail-index-warp'>
        <Head toLink={this.scrollToAnchor} currMenu='cooperate' className='cooperate-detail-warp-header' />
        <div className='cooperate-detail-banner-warp'>
          <img src={cooperateLogo} alt="" />
          <a className='banner-warp-btn'>免费咨询</a>
        </div>
        <Space
          direction="vertical"
          style={{
            width: 'inherit',
          }}
          size={[0, 48]}
        >
          <Layout className='cooperate-detail-warp-content'>
            <Content style={this.state.contentStyle}>
              <div style={{ background: 'rgba(225,234,255,.3)' }}><img src={page1} style={{ width: '100%' }} alt="" /></div>
              <div><img src={page2} alt="" /></div>
              <div><img src={page3} alt="" /></div>
              <div><img src={page4} alt="" /></div>
              <div><img src={page5} alt="" /></div>
              <div><img src={page6} alt="" /></div>
              <div><img src={page7} alt="" /></div>
              <div><img src={page8} alt="" /></div>
              <div><img src={page9} alt="" /></div>
            </Content>
          </Layout>
        </Space>
        <Footer />
      </div>
    )
  }
} 