import './Banner.css'
import { Carousel } from 'antd';
import banner1 from '../../../../image/banner/banner1.png'
import banner2 from '../../../../image/banner/banner2.png'
import banner3 from '../../../../image/banner/banner3.png'
import banner4 from '../../../../image/banner/banner4.png'

const bannerList = [
  {
    key: 0,
    img: banner1,
    src: 'https://ldkj.feishu.cn/share/base/form/shrcngfmZgPnboPENOzhhUMqcOc'
  },
  {
    key: 1,
    img: banner2,
    src: 'https://ldkj.feishu.cn/share/base/form/shrcngfmZgPnboPENOzhhUMqcOc'
  },
  {
    key: 2,
    img: banner3,
    src: 'https://ldkj.feishu.cn/share/base/form/shrcngfmZgPnboPENOzhhUMqcOc'
  },
  {
    key: 3,
    img: banner4,
    src: 'https://ldkj.feishu.cn/share/base/form/shrcngfmZgPnboPENOzhhUMqcOc'
  }
]

const Banner = () => {
  return (
    <Carousel
      autoplay={true}
      autoplaySpeed={5000}
      className='banner-warp'
    >
      {bannerList.map((item, index) => {
        return <div className="banner-warp-item" key={index}>
          <img className="banner-img" src={item.img} alt="" />
          <a target="_blank" className="banner-btn" href={item.src}>免费试用</a>
        </div>
      })}
    </Carousel>
  );
};
export default Banner;