import "./Footer.css";
import footer1 from "../../image/footer/footer1.png";
import footer2 from "../../image/footer/footer2.png";

const Footer = () => {
  return (
    <div id="aboutUs" className="footer-warp">
      <div className="footer-warp-item">
        <img className="footer-warp-item-img" src={footer1} alt="" />
      </div>
      <div className="footer-warp-item">
        <img className="footer-warp-item-img" src={footer2} alt="" />
      </div>
      <div className="footer-warp-item">
        Copyright © 2018 The Project by Ant Motion. All Rights Reserved.
        备案号：
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
          粤ICP备19141034号-1
        </a>
      </div>
    </div>
  );
};
export default Footer;
