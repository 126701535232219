import React from 'react';
import { Layout, Space } from 'antd';
import './Serve.css'
import serve from '../../../../image/serve.png'
const { Header, Content } = Layout;

const headerStyle = {
  textAlign: 'center',
  color: '#1F2329',
  height: 180,
  paddingInline: 424,
  backgroundColor: '#FFFFFF',
  lineHeight: '20px'
};
const contentStyle = {
  textAlign: 'center',
  // minHeight: 300,
  lineHeight: 0,
  backgroundColor: '#FFFFFF',
};

const Serve = () => {
  
  return(
    <Space
      className='serve-warp'
      direction="vertical"
      style={{
        width: 'inherit',
      }}
      size={[0, 48]}
    >
      <Layout>
        <Header style={headerStyle}>
          <div className='warp-header'>
            <div className='header-title'>完善的服务能力</div>
            <div className='header-details'>为不同行业的企业，提供更好用的解决方案</div>
          </div>
        </Header>
        <Content style={contentStyle}>
          <div className="content-warp">
            <img src={serve} alt="" />
          </div>
        </Content>
      </Layout>
    </Space>
  )
};
export default Serve;