import { Component } from 'react';
import { Index } from './pages/index/Index'
import Project from './pages/project/Project'
import { Products } from './pages/products/Products'
import { Cooperate } from './pages/cooperate/Cooperate'
import { CooperateDetail } from './pages/cooperate/components/cooperate-detail/CooperateDetail'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './style/comm.css'

export default class App extends Component{
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Index />}></Route>
          <Route path='/project' element={<Project />}></Route>
          <Route path='/products' element={<Products />}></Route>
          <Route path='/cooperate' element={<Cooperate />}></Route>
          <Route path='/cooperate-detail' element={<CooperateDetail />}></Route>
        </Routes>
      </BrowserRouter>
    )
  }
}