import React from 'react';
import { Row, Col, Layout, Space } from 'antd';
import './Products.css'
import { Link } from 'react-router-dom'
import products1 from '../../../../image/products/products1.png'
import products2 from '../../../../image/products/products2.png'
import products3 from '../../../../image/products/products3.png'
import products4 from '../../../../image/products/products4.png'
const { Header, Content } = Layout;

const headerStyle = {
  textAlign: 'center',
  color: '#1F2329',
  height: 180,
  paddingInline: 219,
  backgroundColor: '#FFFFFF',
  lineHeight: '20px'
};
const contentStyle = {
  textAlign: 'center',
  paddingInline: 70,
  lineHeight: '300px',
  backgroundColor: '#FFFFFF',
};

const productsList = [
  {
    key: 0,
    img: products1,
    btnList: [
      {
        text: '免费试用',
        src: 'https://ldkj.feishu.cn/share/base/form/shrcngfmZgPnboPENOzhhUMqcOc'
      },
      {
        text: '查看详情',
        src: '/project'
      }
    ]
  },
  {
    key: 1,
    img: products2,
    btnList: [
      {
        text: '免费试用',
        src: 'https://ldkj.feishu.cn/share/base/form/shrcngfmZgPnboPENOzhhUMqcOc'
      },
      {
        text: '查看详情',
        src: '/products'
      }
    ]
  },
  {
    key: 2,
    img: products3,
    btnList: [
      {
        text: '免费试用',
        src: 'https://ldkj.feishu.cn/share/base/form/shrcngfmZgPnboPENOzhhUMqcOc'
      },
      {
        text: '查看详情',
        src: '/cooperate'
      }
    ]
  },
  {
    key: 3,
    img: products4,
    btnList: [
      {
        text: '立即咨询',
        src: 'https://ldkj.feishu.cn/share/base/form/shrcngfmZgPnboPENOzhhUMqcOc'
      }
    ]
  }
]

const Products = () => {
  
  return(
    <Space
      className='products-warp'
      direction="vertical"
      style={{
        width: 'inherit',
      }}
      size={[0, 48]}
    >
      <Layout>
        <Header style={headerStyle}>
          <div className='warp-header'>
            <div className='header-title'>产品和服务</div>
            <div className='header-details'>专注企业办公领域，助力企业数字化升级</div>
          </div>
        </Header>
        <Content style={contentStyle}>
          <Row className="content-warp">
            {productsList.map((item, index) => {
              return <Col key={index} xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <div className='content-warp-item' style={{ backgroundImage: 'url(' + item.img + ')' }}>
                  <div className='warp-item-btn'>
                    <div className='item-btn-content'>
                      {item.btnList.map((btn, i) => {
                        return btn.text == '查看详情' ? <Link className="btn-content-item" to={btn.src}>{btn.text}</Link> : <a key={i} className="btn-content-item" target="_blank" href={btn.src}>{btn.text}</a>
                      })}
                    </div>
                  </div>
                </div>
              </Col>
            })}
          </Row>
        </Content>
      </Layout>
    </Space>
  )
};
export default Products;