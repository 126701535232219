import { Component } from 'react'
import './Products.css'
import Head from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import productsLogo from '../../image/products-logo.png'
import { Row, Col, Layout, Space, Typography } from 'antd';
const { Content } = Layout;
const { Title } = Typography;

export class Products extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentStyle: {
        textAlign: 'center',
        lineHeight: 0,
        height: 1030,
        paddingInline: 130,
        backgroundColor: 'transparent',
      },
      productsList: [
        {
          key: 0,
          label: '金蝶云星空集成飞书人事',
          src: 'https://ldkj.feishu.cn/docx/PYnddAvTbooNGxxgBWHcMrDnnzh'
        },
        {
          key: 1,
          label: 'Work day、SAP集成飞书',
          src: 'https://ldkj.feishu.cn/docx/UGZydDrmpo34fixT9vFcuHkLnjh'
        },
        {
          key: 3,
          label: '直播电商行业售后服务管理',
          src: 'https://ldkj.feishu.cn/docx/DNmmdZADQoh57kxWYvEcn4panFd'
        },
        {
          key: 4,
          label: 'Mongo DB集成多维表格、SAP集成飞书通讯录',
          src: 'https://ldkj.feishu.cn/docx/Odq1d9MdoojQe6xsqT5cYvFhn2k'
        },
        {
          key: 5,
          label: '“一张表”定制商务服务业工作台',
          src: 'https://ldkj.feishu.cn/docx/SoG9dQSjyopW94xrkzmc61rNnih'
        },
        {
          key: 6,
          label: '工信ERP系统订单数据集成飞书',
          src: 'https://ldkj.feishu.cn/docx/JGPsdTWZOoN4m2x6iShc3eCenRf'
        },
        {
          key: 7,
          label: '定制搭建船舶保险业飞书知识库实施方案',
          src: 'https://ldkj.feishu.cn/docx/Cq5ldrnego7015xdF3Tcr9Dlnaf'
        }
      ],
      productsMenu: [
        {
          key: 0,
          label: '金蝶云星空集成飞书人事',
        },
        {
          key: 1,
          label: 'Work day、SAP集成飞书',
        },
        {
          key: 3,
          label: '直播电商行业售后服务管理',
        },
        {
          key: 4,
          label: 'Mongo DB集成多维表格、SAP集成飞书通讯录',
        },
        {
          key: 5,
          label: '“一张表”定制商务服务业工作台',
        },
        {
          key: 6,
          label: '工信ERP系统订单数据集成飞书',
        },
        {
          key: 7,
          label: '定制搭建船舶保险业飞书知识库实施方案',
        }
      ],
      currentIndex: 0
    }
  }
  // 卸载
  componentWillUnmount() {
  }
  // 创建阶段
  componentDidMount() {
    if (window.location.search) {
      let search = window.location.search.substring(1)
      let key = search.split('=')
      this.setState({ currentIndex: Number(key[1]) })
    }
    window.scrollTo(0,0)
  }
  handleChangeProductCurr = index => {
    this.setState({ currentIndex: index })
  }
  scrollToAnchor = anchorName => {
    if (anchorName == 'aboutUs') {
      let anchorElement = document.getElementById(anchorName)
      if (anchorElement) {
        window.scrollTo(0,anchorElement.offsetTop - window.innerHeight / 2)
      }
    }
  }
  onClick = e => {
    this.setState({ currentIndex: Number(e.key) })
  }
  render() {
    return (
      <div className='products-warp'>
        <Head toLink={this.scrollToAnchor} currMenu='solution' className='products-warp-header' />
        <div className='products-banner-warp'>
          <img src={productsLogo} alt="" />
          <a className='banner-warp-btn'>免费咨询</a>
        </div>
        <Space
          direction="vertical"
          style={{
            width: 'inherit',
          }}
          size={[0, 48]}
        >
          <Layout className='products-warp-content'>
            <Content style={this.state.contentStyle}>
              <Row>
                <Col className='warp-content-left' xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                  <div className='content-title'><Title style={{fontSize: '34px',textAlign: 'left'}}>定制解决方案</Title></div>
                  <ul className='content-ul'>
                    {this.state.productsList.map((item, index) => {
                      return <li className={index == this.state.currentIndex ? "content-txt content-txt-active" : "content-txt"} key={index} onClick={e => this.handleChangeProductCurr(index)}>
                        <span>{item.label}</span>
                        {index == this.state.currentIndex ? <svg t="1691479354079" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4163" width="16" height="16"><path d="M729.6 448H128v85.333333h601.6L597.333333 665.6l59.733334 59.733333 234.666666-234.666666L661.333333 256l-59.733333 59.733333 128 132.266667z" fill="#1F2329" p-id="4164"></path></svg> : null}
                      </li>
                    })}
                  </ul>
                </Col>
                <Col className='warp-content-right' xs={19} sm={19} md={19} lg={19} xl={19} xxl={19}>
                  <div className='content-title'><Title>&nbsp;</Title></div>
                  <iframe className='content-iframe' src={this.state.productsList[this.state.currentIndex].src} frameborder="0"></iframe>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Space>
        <Footer />
      </div>
    )
  }
} 