import { Component } from 'react'
import './Cooperate.css'
import Head from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import cooperateLogo from '../../image/cooperate-logo.png'
import cooperate1 from '../../image/cooperate/cooperate1.png'
import cooperate2 from '../../image/cooperate/cooperate2.png'
import cooperate3 from '../../image/cooperate/cooperate3.png'
import cooperate4 from '../../image/cooperate/cooperate4.png'
import cooperate5 from '../../image/cooperate/cooperate5.png'
import { Row, Col, Layout, Space } from 'antd';
import { Link } from 'react-router-dom'
const { Content } = Layout;


export class Cooperate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentStyle: {
        textAlign: 'center',
        lineHeight: 0,
        paddingInline: 120,
        backgroundColor: 'transparent',
      },
      cooperateList: [
        {
          key: 0,
          title: '全部',
          list: [
            {
              key: 0,
              img: cooperate1,
              href: ''
            },
            {
              key: 1,
              img: cooperate2,
              href: ''
            },
            {
              key: 2,
              img: cooperate3,
              href: ''
            },
            {
              key: 3,
              img: cooperate4,
              href: ''
            },
            {
              key: 0,
              img: cooperate1,
              href: ''
            },
            {
              key: 1,
              img: cooperate2,
              href: ''
            },
            {
              key: 2,
              img: cooperate3,
              href: ''
            },
            {
              key: 3,
              img: cooperate4,
              href: ''
            },
            {
              key: 0,
              img: cooperate1,
              href: ''
            },
            {
              key: 1,
              img: cooperate2,
              href: ''
            },
            {
              key: 2,
              img: cooperate3,
              href: ''
            },
            {
              key: 3,
              img: cooperate4,
              href: ''
            }
          ]
        },
        {
          key: 1,
          title: '电商',
        },
        {
          key: 3,
          title: '律所',
        },
        {
          key: 4,
          title: '餐饮',
        },
        {
          key: 5,
          
          title: '消费零售',
        },
        {
          key: 6,
          title: '文娱',
        },
        {
          key: 7,
          title: 'MCN',
        },
        {
          key: 8,
          title: '金融',
        },
        {
          key: 9,
          title: '制造业',
        },
        {
          key: 10,
          title: '消费电子',
        },
        {
          key: 11,
          title: '游戏',
        },
        {
          key: 12,
          title: '其它',
        }
      ],
      currentIndex: 0
    }
  }
  // 卸载
  componentWillUnmount() {
  }
  // 创建阶段
  componentDidMount() {
    window.scrollTo(0,0)
  }
  handleChangeProductCurr = index => {
    this.setState({ currentIndex: index })
  }
  scrollToAnchor = anchorName => {
    if (anchorName == 'aboutUs') {
      let anchorElement = document.getElementById(anchorName)
      if (anchorElement) {
        window.scrollTo(0,anchorElement.offsetTop - window.innerHeight / 2)
      }
    }
  }
  render() {
    return (
      <div className='cooperate-index-warp'>
        <Head toLink={this.scrollToAnchor} currMenu='cooperate' className='cooperate-warp-header' />
        <div className='cooperate-banner-warp'>
          <img src={cooperateLogo} alt="" />
          <a className='banner-warp-btn'>免费咨询</a>
        </div>
        <Space
          direction="vertical"
          style={{
            width: 'inherit',
          }}
          size={[0, 48]}
        >
          <Layout className='cooperate-warp-content'>
            <Content style={this.state.contentStyle}>
            <Row className='cooperate-warp-content-title'>
                {this.state.cooperateList.map((item, index) => {
                  return <Col key={index} xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                    <div className={this.state.currentIndex == item.key ? 'title-active' : ''}>{item.title}</div>
                  </Col>
                })}
              </Row>
              <Row>
                <Col className='cooperate-warp-content-col' xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Link to="/cooperate-detail" className='cooperate-example-content'>
                    <img src={cooperate5} alt="" />
                    {/* <div className='cooperate-example-title'>金色庄园</div>
                    <div className='cooperate-example-info'>以飞书助力农业新传承，数字...</div> */}
                  </Link>
                </Col>
                {this.state.cooperateList[this.state.currentIndex].list.map((item, index) => {
                  return <Col className='cooperate-warp-content-col' key={index} xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <img src={item.img} alt="" />
                  </Col>
                })}
              </Row>
            </Content>
          </Layout>
        </Space>
        <Footer />
      </div>
    )
  }
} 