import React, { Component } from 'react';
import { Layout, Space, Carousel } from 'antd';
import { Link } from 'react-router-dom'
import './Project.css'
import approval from '../../../../image/project/approval.png'
import task from '../../../../image/project/task.png'
const { Header, Content } = Layout;

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      projectList: [
        {
          key: 0,
          name: 'approval',
          img: approval,
          href: '/project'
        },
        {
          key: 1,
          name: 'task',
          img: task,
          href: '/project'
        }
      ],
      contentStyle: {
        textAlign: 'center',
        // minHeight: 300,
        lineHeight: 0,
        backgroundColor: '#FFFFFF',
      },
      headerStyle: {
        textAlign: 'center',
        color: '#646A73',
        height: 100,
        paddingInline: 50,
        backgroundColor: '#FFFFFF',
        lineHeight: '80px'
      }
    }
    this.goTo = this.goTo.bind(this);
    this.afterChange = this.afterChange.bind(this)
  }
  afterChange(slideNumber) {
    this.setState({ current: slideNumber })
  }
  goTo(slideNumber) {
    this.setState({ current: slideNumber })
    this.slider.goTo(slideNumber);
  }
  render() {
    const carouselSetting = {
      dots: false,
      lazyLoad: true,
      autoplay:true,
    }
    return (
      <Space
        className='project-warp'
        direction="vertical"
        style={{
          width: 'inherit',
        }}
        size={[0, 48]}
      >
        <Layout>
          <Header style={this.state.headerStyle}>
            <div className={this.state.current == 0 ? 'header-btn-active no-select' : 'header-btn no-select'} onClick={e=>this.goTo(0)}>打印宝</div>
            <div className={this.state.current == 1 ? 'header-btn-active no-select' : 'header-btn no-select'} onClick={e=>this.goTo(1)}>轮动任务</div>
          </Header>
          <Content style={this.state.contentStyle}>
            <Carousel {...carouselSetting} ref={el => (this.slider = el)} afterChange={this.afterChange}>
              {this.state.projectList.map((item, index) => {
                return <div className="content-warp" key={index}>
                  <img src={item.img} alt="" />
                  <Link to={item.href + '?key=' + item.key} className="content-warp-btn">了解详情</Link>
                </div>
              })}
            </Carousel>
          </Content>
        </Layout>
      </Space>
      )
  }
}

export default Project;