import React, { useState, useEffect } from 'react';
import { Row, Col, Layout, Space, Menu, ConfigProvider } from 'antd';
import './Header.css'
import { Link } from 'react-router-dom'
import logo from '../../image/header-logo.png'
const { Header } = Layout;

const headerStyle = {
  height: 80,
  lineHeight: '30px',
  paddingTop: '25px',
  paddingInline: 130,
  background: 'transparent'
}

const itemsXl = [
  {
    label: (
      <Link to="/">首页</Link>
    ),
    key: 'index'
  },
  {
    label: '产品和服务',
    key: 'products',
    children: [
      {
        label: (
          <Link to="/project">企业办公应用</Link>
        ),
        key: 'officeApplication',
      },
      {
        label: (
          <Link to="/products">定制解决方案</Link>
        ),
        key: 'solution',
      },
      {
        label: '企业效能顾问服务',
        key: 'corporateConsultant',
      }
    ],
  },
  {
    label: (
      <Link to="/cooperate">客户案例</Link>
    ),
    key: 'cooperate'
  },
  {
    label: '联系我们',
    key: 'aboutUs'
  },
  {
    label: (
      <a className='no-select menu-btn' target="_blank" href="https://ldkj.feishu.cn/share/base/form/shrcngfmZgPnboPENOzhhUMqcOc">免费咨询</a>
    ),
    key: 'aboutUs'
  }
]

const itemsSm = [
  {
    label: (
      <Link to="/">首页</Link>
    ),
    key: 'index'
  },
  {
    label: '产品和服务',
    key: 'products',
    children: [
      {
        label: (
          <Link to="/project">企业办公应用</Link>
        ),
        key: 'officeApplication',
      },
      {
        label: (
          <Link to="/products">定制解决方案</Link>
        ),
        key: 'solution'
      },
      {
        label: '企业效能顾问服务',
        key: 'corporateConsultant',
      }
    ],
  },
  {
    label: (
      <Link to="/cooperate">客户案例</Link>
    ),
    key: 'cooperate'
  },
  {
    label: '联系我们',
    key: 'aboutUs'
  },
  ,
  {
    label: (
      <a target="_blank" href="https://ldkj.feishu.cn/share/base/form/shrcngfmZgPnboPENOzhhUMqcOc">
        免费咨询
      </a>
    ),
    key: 'ask'
  }
]

const Head = (props) => {
  const [current, setCurrent] = useState(props.currMenu);
  const [headerBackground, setHeaderBackground] = useState(false)

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeaderBackground(false)
    } else if (window.scrollY > 70) {
      return setHeaderBackground(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  const onClick = (e) => {
    if (e.key == 'aboutUs') {
      props.toLink(e.key)
    } else {
      setCurrent(e.key);
    }
  }
  return(
    <Space
      direction="vertical"
      style={{
        width: 'inherit',
        background: 'transparent',
        padding: 0,
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 100
      }}
      size={[0, 48]}
    >
      <Layout className={headerBackground ? 'header-warp-w' : 'header-warp-t'}>
        <Header style={headerStyle}>
          <Row>
            <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5} className='header-warp-logo'>
              <img src={logo} alt="" />
            </Col>
            <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
              <ConfigProvider
                theme={{
                  token: {
                    colorBgContainer: 'transparent',
                    colorPrimary: '#0B87FC',
                    lineWidthBold: 0,
                    lineWidth: 0,
                    colorText: '#646A73',
                    margin: 20
                  }
                }}
              >
                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={itemsXl} />
              </ConfigProvider>
            </Col>
            {/* <Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={0}>
              <ConfigProvider
                theme={{
                  token: {
                    colorBgContainer: 'transparent',
                    colorPrimary: '#0B87FC',
                    lineWidthBold: 0,
                    lineWidth: 0,
                    colorText: '#646A73',
                    itemActiveBg: 'pink',
                    fontFamily: '苹方',
                  }
                }}
              >
                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={itemsSm} />
              </ConfigProvider>
            </Col> */}
            {/* <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
              <div className='ask-btn-content'>
                <a className='no-select btn' target="_blank" href="https://ldkj.feishu.cn/share/base/form/shrcngfmZgPnboPENOzhhUMqcOc">免费咨询</a>
              </div>
            </Col> */}
          </Row>
        </Header>
      </Layout>
    </Space>
  )
};
export default Head;