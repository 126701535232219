import { Component } from 'react'
import './Project.css'
import Head from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import productsLogo from '../../image/products-logo.png'
import { Row, Col, Layout, Space, Typography } from 'antd';
const { Content } = Layout;
const { Title } = Typography;

class Project extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentStyle: {
        textAlign: 'center',
        lineHeight: 100,
        height: 1030,
        paddingInline: 130,
        backgroundColor: 'transparent',
      },
      projectList: [
        {
          key: 0,
          title: '打印宝',
          src: 'https://ldkj.feishu.cn/docs/doccnGctAVyBuEHnm78TIzTtmVc'
        },
        {
          key: 1,
          title: '轮动任务',
          src: 'https://ldkj.feishu.cn/docs/doccn4pxg1JYfvNdjTiPQEODOBb'
        }
      ],
      currentIndex: 0
    }
  }
  // 卸载
  componentWillUnmount() {
  }
  // 创建阶段
  componentDidMount() {    
    if (window.location.search) {
      let search = window.location.search.substring(1)
      let key = search.split('=')
      this.setState({ currentIndex: Number(key[1]) })
    }
    window.scrollTo(0,0)
  }
  handleChangeProductCurr = index => {
    this.setState({ currentIndex: index })
  }
  scrollToAnchor = anchorName => {
    if (anchorName == 'aboutUs') {
      let anchorElement = document.getElementById(anchorName)
      if (anchorElement) {
        window.scrollTo(0,anchorElement.offsetTop - window.innerHeight / 2)
      }
    }
  }
  render() {
    return (
      <div className='project-warp'>
        <Head toLink={this.scrollToAnchor} currMenu='officeApplication' className='project-warp-header' />
        <div className='project-banner-warp'>
          <img src={productsLogo} alt="" />
          <a className='banner-warp-btn'>免费咨询</a>
        </div>
        <Space
          direction="vertical"
          style={{
            width: 'inherit',
          }}
          size={[0, 48]}
        >
          <Layout className='project-warp-content'>
            <Content style={this.state.contentStyle}>
              <Row>
                <Col className='warp-content-left' xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                  <div className='content-title'><Title style={{fontSize: '34px', textAlign: 'left'}}>定制解决方案</Title></div>
                  <ul className='content-ul'>
                    {this.state.projectList.map((item, index) => {
                      return <li className={index == this.state.currentIndex ? "content-txt content-txt-active" : "content-txt"} key={index} onClick={e => this.handleChangeProductCurr(index)}>
                        <span>{item.title}</span>
                        {index == this.state.currentIndex ? <svg t="1691479354079" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4163" width="16" height="16"><path d="M729.6 448H128v85.333333h601.6L597.333333 665.6l59.733334 59.733333 234.666666-234.666666L661.333333 256l-59.733333 59.733333 128 132.266667z" fill="#1F2329" p-id="4164"></path></svg> : null}
                      </li>
                    })}
                  </ul>
                </Col>
                <Col className='warp-content-right' xs={19} sm={19} md={19} lg={19} xl={19} xxl={19}>
                  <div className='content-title'><Title>&nbsp;</Title></div>
                  <iframe className='content-iframe' src={this.state.projectList[this.state.currentIndex].src} frameborder="0"></iframe>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Space>
        <Footer />
      </div>
    )
  }
} 
export default Project;